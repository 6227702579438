import { ValidatorFn, FormGroup, FormControl } from '@angular/forms';

export const requireCheckboxesToBeCheckedValidator = (
  minRequired = 1
): ValidatorFn => {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true
      };
    }

    return null;
  };
};

export const validateEmail: ValidatorFn = (control: FormControl) => {
  const email = control.value as string;
  // tslint:disable-next-line:max-line-length
  const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = email && regExp.test(email.toLowerCase());

  if (!email || valid) {
    return null;
  }
  return {
    email: 'invalid email'
  };
};

export const noWhitespaceValidator: ValidatorFn = (control: FormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};
