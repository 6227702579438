import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { BaseService } from '@shared/services/base.service';
import {
  IDocumentation,
  IDocumentationDeleteResponse,
  IDocumentationUploadConfig
} from './documentation.model';
import { Observable, Subject } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService extends BaseService {
  constructor(private _http: HttpClient) {
    super();
  }

  apiBase = '/Companies';

  getDocumentationsList(companyId: number) {
    return this._http.get<IDocumentation[]>(
      this.url(`${this.apiBase}/${companyId}/documentations`)
    );
  }

  updateDocumentation(documentation: IDocumentation) {
    return this._http.put<IDocumentation>(
      this.url(`/CompanyDocuments/${documentation.id}`),
      documentation
    );
  }

  deleteDocumentation(documentId: number) {
    return this._http.delete<IDocumentationDeleteResponse>(
      this.url(`/CompanyDocuments/${documentId}`)
    );
  }

  uploadDocumentation(files: File[], config: IDocumentationUploadConfig) {
    const status: { [key: string]: { progress: Observable<number> } } = {};

    files.forEach(file => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('uploadType', 'document');
      formData.append('companyId', config.companyId);
      formData.append('locale', config.locale);
      formData.append('cfe', config.cfe);
      formData.append('name', file.name);

      const req = new HttpRequest(
        'POST',
        this.url('/Storages/tmp/upload'),
        formData,
        {
          reportProgress: true
        }
      );

      const progress = new Subject<number>();
      this._http.request(req).subscribe(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            progress.next(0);
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round((100 * event.loaded) / event.total);
            progress.next(percentDone);
            break;
          case HttpEventType.Response:
            progress.complete();
            break;
          default:
            progress.complete();
            break;
        }
      });

      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    return status;
  }
}
