import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ICompany,
  IPlan,
  IDeleteCompanyResponse,
  IUploadLogoResponse,
  ICompanySaveChanges
} from '././companies.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService {
  constructor(private _http: HttpClient) {
    super();
  }

  apiBase = '/Companies';

  getCompaniesList() {


    let reqParams =
    {
      "filter": {
        "where": { type: 'INDIVIDUAL' }
      }
    }

    let params = new HttpParams()

    // Support easy query params for GET requests

    for (let k in reqParams) {

      if (k == 'fields' || k == 'filters' || k == 'filter') {
        params = params.set(k, JSON.stringify(reqParams[k]));
      } else {
        params = params.set(k, reqParams[k]);
      }
    }

    return this._http.get<ICompany[]>(this.url(this.apiBase), { params });
  }

  getCompany(companyId: string) {
    return this._http.get<ICompany>(this.url(`${this.apiBase}/${companyId}`));
  }

  uploadCompanyLogo(companyId: string, logo: File) {
    const body = new FormData();
    // body.append('uploadType', 'logo');
    body.append('companyId', companyId);
    body.append('file', logo);

    return this._http.post<IUploadLogoResponse>(
      this.url('/Storages/logos/upload/'),
      body
    );
  }

  getCompanyPlans(companyId: string) {
    return this._http.get<IPlan[]>(
      this.url(`${this.apiBase}/${companyId}/insurancePlans`)
    );
  }

  createCompany(name: string, description: string, type: string) {
    return this._http.post<ICompany>(this.url(this.apiBase), {
      name,
      description,
      type
    });
  }

  updateCompany(company: ICompanySaveChanges) {
    return this._http.put<ICompanySaveChanges>(
      this.url(`${this.apiBase}/${company.id}`),
      company
    );
  }

  toggleEnableCompany(companyId: string, isVisible: boolean) {
    return this._http.patch<ICompany>(this.url(`${this.apiBase}/${companyId}`), {
      isVisible
    });
  }

  deleteCompany(companyId: string) {
    return this._http.delete<IDeleteCompanyResponse>(
      this.url(`${this.apiBase}/${companyId}`)
    );
  }
}
